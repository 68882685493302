import './App.css';
import Meteostr from './components/Meteostr';

function App() {
  return (
    <div className="main-div">
      <Meteostr></Meteostr>
    </div>
  );
}

export default App;
